import React from 'react';
import Header from '../Components/Header';
import Alt from '../Components/Alt';
import ReactTypingEffect from 'react-typing-effect';
import { NavLink } from 'react-router-dom';
import Sparkles from '../Assets/Sparkles.svg';

export default function Home() {

  return (
    <div className="w-full h-full bg-neutral-900 flex flex-col text-zinc-200 z-[1] overflow-x-hidden">
      <Header />

      <div className="w-full h-full flex pt-40 flex-col items-center">
        <h1 className='text-8xl font-extrabold max-w-3xl text-center w-full select-none min-h-[248px]'>
          <span href="" className='w-max h-max bg-neutral-800 px-4 py-0 font-semibold rounded-2xl'>
            <ReactTypingEffect eraseDelay={5000} typingDelay={100} cursorClassName='text-zinc-200 w-[4px] h-max bg-zinc-500' cursorRenderer={() => <span>&thinsp;</span>} speed={100} text={['nowon', 'skyhan', 'uzikedy']} />
          </span>
          <span>.is-</span>
          <span href="" className='w-max h-max inline-flex fancy'>
            my.bio
            <span className='absolute w-full h-full flex items-center'>
              <img src={Sparkles} alt="" className='w-full h-auto'/>
            </span>
          </span>
        </h1>

        <div className='flex flex-col items-center max-w-2xl mb-64'>
          <p className='text-neutral-400 text-sm text-center leading-6'>
          nowon.is-my.bio basit, ücretsiz ve açık kaynaklı bir biyo bağlantı hizmetidir. Sosyal medya bağlantılarınızı, iletişim bilgilerinizi ve daha fazlasını paylaşmanın harika bir yoludur.
          </p>

          <div className='flex flex-row gap-x-4 items-center mt-4'>
            <NavLink to='/login' className='select-none text-white font-medium bg-indigo-500 rounded px-8 py-2 hover:bg-indigo-600'>
              Giriş Yap
            </NavLink>

            <NavLink to='/register' className='select-none text-white font-medium bg-neutral-700 rounded px-8 py-2 hover:bg-zinc-700/80'>
              Kayıt Ol
            </NavLink>
          </div>
        </div>
      </div>

      <Alt />
    </div>
  )
};