import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RiGlobalFill } from 'react-icons/ri';
import { BiRightArrowAlt } from 'react-icons/bi';

export default function Header() {

  const location = useLocation();
  const { pathname } = location;

  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const elements = document.querySelectorAll('a[href^="#"]');
    for (const element of elements) {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        const id = element.getAttribute('href').slice(1);
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }

    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <span className={`sticky top-0 left-0 w-full h-2 bg-neutral-800 transition-all z-[2]`}>&thinsp;</span>

      <div className={`sticky lg:px-48 top-2 px-6 py-4 w-full h-14 flex items-center justify-between z-[3] transition-all ${isScrolled && 'bg-neutral-900/50 backdrop-blur-lg'}`}>
        <div className='w-full h-full flex items-center justify-start'>
          <NavLink to='/' className='header-item bg-neutral-800 hover:bg-neutral-700'>
            <RiGlobalFill className='text-md' />
            is-my.bio
          </NavLink>
        </div>
        <div className='w-full h-full flex items-center justify-end gap-x-2'>
          <NavLink to='/' className={`header-item ${pathname == '/' && 'active-header-item'}`}>Anasayfa</NavLink>
          <a href='#features' className={`header-item`}>Özellikler</a>
          <NavLink to='/profiles' className={`header-item ${pathname == '/profiles' && 'active-header-item'}`}>Profiller</NavLink>

          <NavLink to='/login' className={`primary-button ${pathname == '/login' && 'active-primary-button'} rounded`}>
            Giriş Yap
            <BiRightArrowAlt className='text-base' />
          </NavLink>
        </div>
      </div>
    </>
  )
};