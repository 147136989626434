import { BsFacebook, BsLinkedin, BsInstagram, BsDiscord } from 'react-icons/bs';


export default function Footer() {
  return (
    <div className='w-full h-full bg-neutral-800/40'>
      <div className="w-full h-full flex flex-row justify-between items-center py-16 px-24">
        
        <div className='flex flex-col gap-y-3'>
          <h1 className='text-neutral-300 font-bold text-2xl'>nowon.is-my.bio</h1>
          <p className='text-neutral-300 text-sm max-w-[300px] leading-5'>nowon.is-my.bio basit, ücretsiz ve açık kaynaklı bir biyo bağlantı hizmetidir. Sosyal medya bağlantılarınızı, iletişim bilgilerinizi ve daha fazlasını paylaşmanın harika bir yoludur.</p>
          <div className="flex flex-row gap-3 w-max">
            <a href="https://www.facebook.com/nowon.ismy.bio" target="_blank" rel="noreferrer" className='text-neutral-400 hover:text-neutral-500 transition-colors duration-200'>
              <BsFacebook size={20} />
            </a>

            <a href="https://www.linkedin.com/in/nowonismybio/" target="_blank" rel="noreferrer" className='text-neutral-400 hover:text-neutral-500 transition-colors duration-200'>
              <BsLinkedin size={20} />
            </a>

            <a href="https://www.instagram.com/nowon.ismy.bio/" target="_blank" rel="noreferrer" className='text-neutral-400 hover:text-neutral-500 transition-colors duration-200'>
              <BsInstagram size={20} />
            </a>

            <a href="https://discord.gg/nowonismybio" target="_blank" rel="noreferrer" className='text-neutral-400 hover:text-neutral-500 transition-colors duration-200'>
              <BsDiscord size={20} />
            </a>
          </div>
        </div>

        <div className='flex flex-row gap-x-24 w-full h-full flex-1 justify-end'>
          
          <div className='flex flex-col'>
            <h1 className='text-zinc-300 font-semibold text-xl tracking-wider'>Sayfalar</h1>
            <ul className='flex flex-col gap-y-2 mt-4 text-sm'>
              <li><a href="/login" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Giriş Yap</a></li>
              <li><a href="/register" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Kayıt Ol</a></li>
              <li><a href="/dashboard" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Kontrol Paneli</a></li>
            </ul>
          </div>

          <div className='flex flex-col'>
            <h1 className='text-zinc-300 font-semibold text-xl tracking-wider'>Sayfalar</h1>
            <ul className='flex flex-col gap-y-2 mt-4 text-sm'>
              <li><a href="/login" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Giriş Yap</a></li>
              <li><a href="/register" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Kayıt Ol</a></li>
              <li><a href="/dashboard" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Kontrol Paneli</a></li>
            </ul>
          </div>

          <div className='flex flex-col'>
            <h1 className='text-zinc-300 font-semibold text-xl tracking-wider'>Sayfalar</h1>
            <ul className='flex flex-col gap-y-2 mt-4 text-sm'>
              <li><a href="/login" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Giriş Yap</a></li>
              <li><a href="/register" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Kayıt Ol</a></li>
              <li><a href="/dashboard" className='text-neutral-300 hover:text-neutral-400 transition-colors duration-200'>Kontrol Paneli</a></li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  )
};